:root {
  --antropomedia-yellow: rgb(236, 171, 32);
  --antropomedia-indigo: rgb(26, 47, 90);
  --antropomedia-blue: rgb(27, 169, 181);
  --antropomedia-cherry: rgb(158, 32, 100);
  --antropomedia-green: rgb(35, 135, 128);
}

.bi {
  display: inline-block;
  vertical-align: -.125em;
}

.bg-am--yellow {
  background-color: var(--antropomedia-yellow);
}

.bg-am--indigo {
  background-color: var(--antropomedia-indigo);
}

.bg-am--blue {
  background-color: var(--antropomedia-blue);
}

.bg-am--cherry {
  background-color: var(--antropomedia-cherry);
}

.bg-am--green {
  background-color: var(--antropomedia-green);
}

.rounded-xl {
  border-radius: 0.75rem;
}

.space-pre-wrap {
  white-space: pre-wrap;
}

.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto
}

@media (min-width:1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1560px
  }
}