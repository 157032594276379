.login-cover {
  background-image: url(../../assets/images/login-cover.jpeg);
  background-position: center;
  background-size: cover;
}
.login-form {
  min-width: 320px!important;
  max-width: 320px!important;
}
.login-form .card-img-top {
  width: 70%;
  align-self: center;
}
.login-form button.btn-primary {
  background-color: var(--antropomedia-indigo);
  border-color: var(--antropomedia-indigo);
}
.login-form button.btn-primary:active{
  background-color: #102550!important;
  border-color: #0e234e!important;
}
.login-form button.btn-primary:focus{
  background-color: #132853;
  border-color: #102550;
  box-shadow: 0 0 0 0.2rem rgba(var(--antropomedia-indigo), 0.5);
}
.login-form button.btn-primary:hover{
  background-color: #132853;
  border-color: #102550;
}